import React, { Component } from "react";
// Components
import Helmet from "react-helmet";

import Hero from "slices/Hero";
import Story from "slices/Story";
import StaffGrid from "slices/StaffGrid";
import AffiliateList from "slices/AffiliateList";
import BigCallout from "slices/BigCallout";
import getPageMeta from "utils/getPageMeta";

// graphql
import { graphql } from "gatsby";
import FeatureContentWithImage from "slices/FeatureContentWithImage";
import FeatureContent from "slices/FeatureContent";

class AboutPage extends Component {
  state = {
    page: this.props.data.page
  };

  renderSlice = slice => {
    if (!slice) {
      return null;
    }

    let Cmp;
    switch (slice.slice_type) {
      case "hero":
        slice.primary.theme = "red";
        slice.large = true;
        slice.compressed = false;
        Cmp = Hero;
        break;
      case "feature_content_with_image":
        slice.large = true;
        Cmp = FeatureContentWithImage;
        break;
      case "feature_content":
        slice.large = true;
        Cmp = FeatureContent;
        break;
      case "story":
        Cmp = Story;
        break;
      case "staff_grid":
        Cmp = StaffGrid;
        break;
      case "partner_callouts":
        Cmp = AffiliateList;
        break;
      case "callout":
        Cmp = BigCallout;
        break;
      default:
        Cmp = null;
        break;
    }
    if (!Cmp) {
      return null;
    }
    return <Cmp {...slice} key={slice.id} />;
  };

  render() {
    // Set the document object
    const { page } = this.state;
    const { title, body } = page.data;
    return (
      <React.Fragment>
        <Helmet
          title={`${title.text} | Citizen Relations`}
          meta={getPageMeta(
            this.props.pageContext.globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {body.map(slice => {
          return this.renderSlice(slice);
        })}
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query($uid: String!) {
    page: prismicAboutPage(uid: { eq: $uid }) {
      uid
      data {
        seo_description {
          text
        }
        seo_keywords {
          text
        }
        seo_image {
          twitter {
            url
            dimensions {
              width
              height
            }
          }
          facebook {
            url
            dimensions {
              width
              height
            }
          }
        }
        seo_image_alt {
          text
        }
        title {
          text
        }
        body {
          ... on PrismicAboutPageBodyHero {
            id
            slice_type
            primary {
              heading {
                text
                html
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicAboutPageBodyStory {
            id
            slice_type
            primary {
              heading {
                html
                text
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
            items {
              block_heading {
                html
                text
              }
              block_content {
                html
              }
            }
          }
          ... on PrismicAboutPageBodyStaffGrid {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              location {
                text
                html
              }
              person {
                document {
                  id
                  data {
                    image {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    name {
                      text
                    }
                    job_title {
                      html
                    }
                    bio {
                      html
                    }
                    office {
                      document {
                        id
                        data {
                          name {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAboutPageBodyPartnerCallouts {
            id
            slice_type
            primary {
              heading {
                html
              }
              paragraph {
                html
              }
              show_affiliate_link
              show_description
            }
            items {
              affiliate {
                document {
                  id
                  data {
                    website_url {
                      url
                    }
                    name {
                      text
                    }
                    description {
                      html
                    }
                    image {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAboutPageBodyCallout {
            id
            slice_type
            primary {
              theme
              button_label
              heading {
                text
              }
              link {
                document {
                  slug
                  data {
                    title {
                      text
                    }
                  }
                }
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicAboutPageBodyFeatureContentWithImage {
            id
            slice_type
            primary {
              theme
              orientation
              image_animation
              small_heading {
                text
              }
              tagline {
                text
                html
              }
              paragraph {
                text
                html
              }
            }
            items {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicAboutPageBodyFeatureContent {
            id
            slice_type
            primary {
              theme
              tagline {
                text
                html
              }
              paragraph {
                text
                html
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
